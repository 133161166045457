<template>
  <router-view />
</template>

<script setup>
</script>
<style>
#app {
  font-family: Arial, Avenir, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
}
body,
p {
  margin: 0;
  padding: 0;
}
ol,
ul {
  counter-reset: list-number;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.web-container {
  min-width: 1200px;
}
::-webkit-scrollbar {
  width: 5px; /* 纵向滚动条*/
  height: 5px; /* 横向滚动条 */
  background-color: #fff;
}

/*定义滚动条轨道 内阴影*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #fff;
}

/*定义滑块 内阴影*/
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: rgba(164, 166, 172, 0.3);
  border-radius: 10px;
}

.ͼr {
  color: #bf53df;
}
.ͼq {
  color: #3395ff;
}
.ͼw {
  color: #999999;
}
.cm-editor {
  background-color: #2b2f34;
  font-size: 16px;
}
.cm-editor-small .cm-editor {
  font-size: 14px !important;
}
.code-edit ::-webkit-scrollbar {
  width: 5px; /* 纵向滚动条*/
  height: 5px; /* 横向滚动条 */
  background-color: #2b2f34;
}

.content-content {
  white-space: pre-wrap;
  line-height: 25px;
}
.content-content img {
  max-width: 100%;
}
/*定义滚动条轨道 内阴影*/
.code-edit ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #2b2f34;
}

/*定义滑块 内阴影*/
.code-edit ::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: rgba(210, 211, 215, 0.3);
  border-radius: 10px;
}
.code-edit ::-webkit-scrollbar-corner {
  background-color: #2b2f34;
}
</style>

