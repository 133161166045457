import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  { path: "/", redirect: "/web/main" },
  { path: '/web/main', name: 'home', component: () => import('@/views/web/home/home-view.vue'), },
  { path: '/web/job', name: 'job', component: () => import('@/views/web/job/job-index.vue'), },
  { path: '/web/userInfo', name: 'userInfo', component: () => import('@/views/web/user/user-index.vue'), },
  { path: '/web/invite', name: 'invite', component: () => import('@/views/web/invite/invite-index.vue'), },
  { path: '/web/strategy/:dataId', name: 'strategy', component: () => import('@/views/web/class/strategy-index.vue'), },
  { path: '/web/class/:dataId', name: 'class', component: () => import('@/views/web/class/class-index.vue'), },
  { path: '/web/customerService', name: 'customerService', component: () => import('@/views/web/customer-service/customer-index.vue'), },
  { path: '/wap/main', name: 'wapHome', component: () => import('@/views/phone/phone-index.vue') },
  // { path: '/wap/notice', name: 'wapNotice', component: () => import('@/views/phone/notice/phone-notice.vue') },
  { path: '/wap/login', name: 'wapLogin', component: () => import('@/views/phone/login/login-index.vue') },
  { path: '/wap/classCatalog/:dataId', name: 'wapClassCatalog', component: () => import('@/views/phone/class/class-catalog') },
  { path: '/wap/classContent/:catalogSn', name: 'wapClassContent', component: () => import('@/views/phone/class/class-content') },
  { path: '/wap/job', name: 'wapJob', component: () => import('@/views/phone/job/job-index') },
  { path: '/wap/connectUs', name: 'wapConnectUs', component: () => import('@/views/phone/common/connect-us') },
  { path: '/wap/trainingDetail/:trainingSn', name: 'wapTrainingDetail', component: () => import('@/views/phone/project/training-detail') },
  { path: '/wap/jobDetail/:jobSn', name: 'wapJobDetail', component: () => import('@/views/phone/job/job-detail') },
  { path: '/wap/jobApply', name: 'wapJobApply', component: () => import('@/views/phone/job/job-apply') }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
