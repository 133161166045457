import axios from 'axios'
import { Notification } from "@arco-design/web-vue";

//dev
// export const baseUrl = 'http://localhost:6572/paisen/api'
// export const remoteBaseUrl = 'http://localhost:7071/center/api'
// export const webUrl = 'http://localhost:8080/#/web/main'
// export const storagePhoneLabel = 'paisen-study-user-phone-dev'
// export const storageWebLabel = 'paisen-study-user-web-dev'

//prod
export const baseUrl = 'https://www.aiyunquant.com/classApi/api'
export const remoteBaseUrl = 'http://123.60.173.159/paisenApi/center/api'
export const webUrl = 'https://aiyunquant.com/#/web/main'
export const storagePhoneLabel = 'paisen-study-user-phone'
export const storageWebLabel = 'paisen-study-user-web'

// 创建axios的对象
const instance = axios.create({
    baseURL: baseUrl,  //配置固定域名
    timeout: 70000
})

// 请求拦截
// 所有的网络请求都会走这个方法,可以在请求添加自定义内容
instance.interceptors.request.use(
    function (config) {
        config.headers.token = localStorage.getItem(storageLabel()) // 请求头添加token值
        return config
    },
    function (err) {
        return Promise.request(err)
    }
)

// 响应拦截
// 此处可以根据服务器返回的状态码做相应的数据
instance.interceptors.response.use(
    function (response) {
        const res = response
        // console.log('axios-res', res.config.url, res.data)
        if (res.data.status === 500) {
            Notification.error({ title: "提醒", content: res.data.message });
        } else if (res.data.status === 403) {
            localStorage.removeItem(storageLabel());
            console.log('403')
            let urlParam = getUrlParam("p")
            if (urlParam !== '') {
                location.href = './#/web/main?p=' + urlParam
            } else {
                location.href = './#/web/main'
            }
        }
        return res.data
    },
    function (err) {
        // console.log(err)
        return { success: false, message: err }
    }
)

//paraName 等找参数的名称
export function getUrlParam(paraName) {
    var url = document.location.toString();
    var arrObj = url.split("?");
    if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;
        for (var i = 0; i < arrPara.length; i++) {
            arr = arrPara[i].split("=");

            if (arr != null && arr[0] == paraName) {
                return arr[1];
            }
        }
        return "";
    }
    else {
        return "";
    }
}

// 封装get和post请求
export function get(url, params) {
    return instance.get(url, { params })
}

export function post(url, data) {
    return instance.post(url, data)
}

export function storageLabel() {
    let phoneFlag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if (phoneFlag) {
        return storagePhoneLabel
    }else{
        return storageWebLabel
    }

}

export default instance;