import { createApp } from 'vue'
import ArcoVue from '@arco-design/web-vue';
import App from './App.vue'
import router from './router'
import '@arco-design/web-vue/dist/arco.css';
import '@arco-themes/vue-paisen/css/arco.css';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import { post, get, baseUrl, remoteBaseUrl, webUrl, storageLabel, getUrlParam } from '@/utils/axios.js'
import { createPinia } from 'pinia'

const pinia = createPinia()
const app = createApp(App);
app.use(ArcoVue);
app.use(router)
app.use(ArcoVueIcon);
app.config.globalProperties.baseUrl = baseUrl
app.config.globalProperties.remoteBaseUrl = remoteBaseUrl
app.config.globalProperties.webUrl = webUrl
app.config.globalProperties.storageLabel = storageLabel
app.config.globalProperties.post = post
app.config.globalProperties.get = get
app.config.globalProperties.getUrlParam = getUrlParam
app.use(pinia)
app.mount('#app');

router.afterEach((to, from, next) => {
    console.log(to, from, next)
    let phoneFlag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    post("/webView/record", { viewProject: 'class', viewType: phoneFlag ? 'phone' : 'web', fullPath: to.fullPath });
})